import { InnerBlocks } from "@wordpress/block-editor"
import { registerBlockType } from "@wordpress/blocks"

registerBlockType("ourblocktheme/search", {
    title: "Search",
    edit: EditComponent,
    save: SaveComponent
})

function EditComponent() {   
    return (
        <div style={{width: '800px', height: '600px', border: '4px solid black', backgroundColor: 'gray', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '32px'}}>
            Search Results
        </div>
    )
}

function SaveComponent() {
    return <InnerBlocks.Content />
}